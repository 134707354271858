import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/media';

export const ViewWrapper = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return <ViewWrapperInner>{children}</ViewWrapperInner>;
};

export const JobIntroWrapper = ({ children }: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return <JobIntroWrapperInner>{children}</JobIntroWrapperInner>;
};

const ViewWrapperInner = styled.div`
  /* border: 1px solid red; */
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 20px;
  `}

  > div {
    margin-bottom: 30px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

const JobIntroWrapperInner = styled.div``;

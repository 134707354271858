import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { JobPostDescriptionProps } from './JobPostDescription.types';
import {
  ChevronRightIcon,
  CyclingIcon,
  DrivingIcon,
  ExternalLinkIcon,
  HomeIcon,
  LocationIcon,
  MailIcon,
  MoneyBillIcon,
  PeopleIcon,
  PhoneIcon,
  StarIcon,
  SuitcaseIcon,
  TransitIcon,
  WalkingIcon,
} from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { Sections, Section } from '../../atoms/Section';
import { HRSection } from '../../atoms/HRSection';
import { convertEnumToString } from '../../../utils/enum';
import { formatDate } from '../../../utils/date';
import { GoogleMap } from '../../molecules/GoogleMap/GoogleMap';
import { TabController, TabPanel } from '../../atoms/Tabs';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { RichTextInputReadOnly } from '../../atoms/Form/RichTextInput/RichTextInput';
import { File as FileComponent } from '../../atoms/File';
import { JobStatus } from '../../../generated/types';
import { media } from '../../../styles/media';
import { JobPostTableStatus } from '../../molecules/JobTable/JobTable';

export const JobPostDescription: React.FC<any> = ({
  isThroughAgency,
  agencyName,
  agentName,
  agentEmail,
  agentPhone,
  referralFee,
  company,
  companyWebsite,
  companyLinkedIn,
  companyInfo,
  companyLocation,
  companyLocationCity,
  companyLocationCountry,
  companyLocationLatitude,
  companyLocationLongitude,
  companyLocationPostCode,
  companyLocationStreet,
  companyLocationUrl,
  companyDistances,
  jobTitle,
  jobDescription,
  jobRequirement,
  jobUrl,
  ir35,
  duration,
  rate,
  employmentType,
  remoteOption,
  createdAt,
  updatedAt,
  status,
  files,
  permissions,
  onJobStatusClick,
}: React.PropsWithChildren<JobPostDescriptionProps>) => {
  const IsNew = () => {
    const today = new Date();
    const isCreatedAt = new Date(createdAt) > new Date(today.setDate(today.getDate() - 7));
    const isUpdatedAt = new Date(updatedAt) > new Date(today.setDate(today.getDate() - 7));
    return isCreatedAt || isUpdatedAt;
  };
  const coordinates = {
    lat: companyLocationLatitude,
    lng: companyLocationLongitude,
  };

  return (
    <Container>
      <ErrorBoundary>
        {/* <pre>{JSON.stringify({ permissions }, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify({ IsNew }, null, 2)}</pre> */}
        <Intro>
          <IntroMain>
            <MainTitleWrapper>
              <CompanyName>
                {company}
                {Boolean(companyWebsite) && (
                  <Link target="_blank" to={companyWebsite}>
                    <ExternalLinkIcon />
                  </Link>
                )}
              </CompanyName>
              <PillsWrapper>
                <JobPostTableStatus status={status}></JobPostTableStatus>
                {IsNew() && (
                  <Pill icon={<StarIcon />} isSmall={true} color={Colors.Contrast}>
                    New
                  </Pill>
                )}
                {ir35 && (
                  <Pill icon={<MoneyBillIcon />} isSmall={true} color={Colors.Primary}>
                    Within IR35
                  </Pill>
                )}
                {isThroughAgency && (
                  <Pill icon={<PeopleIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                    Agency
                  </Pill>
                )}
              </PillsWrapper>
            </MainTitleWrapper>
            <JobTitle>
              {jobTitle}
              {Boolean(jobUrl) && (
                <Link target="_blank" to={jobUrl}>
                  <ExternalLinkIcon />
                </Link>
              )}
            </JobTitle>
            <Salary>
              {Boolean(rate) && <span>{rate}</span>}
              {Boolean(duration && rate) && <span> / </span>}
              {Boolean(duration) && <span>{duration}</span>}
            </Salary>
            <Employment>
              {employmentType && (
                <EmploymentContent>
                  <SuitcaseIcon size={1.5} />
                  {convertEnumToString('EmploymentType', employmentType)}
                </EmploymentContent>
              )}
              {employmentType && (
                <EmploymentContent>
                  <HomeIcon size={1.5} />
                  {convertEnumToString('RemoteOption', remoteOption)}
                </EmploymentContent>
              )}
            </Employment>
            {companyLocation && (
              <Location>
                <LocationIcon size={1.5} color={Colors.Contrast} />
                {companyLocation}
                {Boolean(companyLocationUrl) && (
                  <LocationUrl target="_blank" to={companyLocationUrl}>
                    <ExternalLinkIcon size={1} />
                  </LocationUrl>
                )}
              </Location>
            )}
          </IntroMain>
          <IntroRight>
            <Section>
              {isThroughAgency ? (
                <>
                  <SectionTitle>Agency</SectionTitle>
                  <SectionValue>{agencyName}</SectionValue>
                  <SectionValue>{agentName}</SectionValue>
                  {agentEmail && (
                    <SectionValue>
                      <MailIcon /> {agentEmail}
                    </SectionValue>
                  )}
                  {agentPhone && (
                    <SectionValue>
                      <PhoneIcon /> {agentPhone}
                    </SectionValue>
                  )}
                </>
              ) : (
                <SectionTitle>No Agency</SectionTitle>
              )}
            </Section>
          </IntroRight>
        </Intro>
      </ErrorBoundary>

      <HRSection />

      <ErrorBoundary>
        <TabController
          headers={[
            { id: 0, label: 'Job Description' },
            { id: 1, label: 'Job Requirement' },
            { id: 2, label: 'Company Info' },
            { id: 3, label: 'Uploaded Files' },
          ]}
        >
          <TabPanel $tabId={0}>
            {jobDescription ? (
              <RichTextInputReadOnly>{jobDescription}</RichTextInputReadOnly>
            ) : (
              'Job Description not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId={1}>
            {jobRequirement ? (
              <RichTextInputReadOnly>{jobRequirement}</RichTextInputReadOnly>
            ) : (
              'Job Requirement not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId={2}>
            {companyInfo ? (
              <RichTextInputReadOnly>{companyInfo}</RichTextInputReadOnly>
            ) : (
              'Company Information not provided.'
            )}
          </TabPanel>
          <TabPanel $tabId={3}>
            {files && files.length > 0 ? (
              <FilesList>
                {files.map((file) => (
                  <FileComponent
                    key={file.uuid}
                    uuid={file.uuid}
                    status="uploaded"
                    mimeType={file.mimetype}
                    formattedMimetype={file.formattedMimetype}
                    filename={file.filename}
                    onDownloadClick={() => {
                      const win = window.open(file.temporaryUrl, '_blank');
                      win?.focus();
                    }}
                  />
                ))}
              </FilesList>
            ) : (
              'No files uploaded.'
            )}
          </TabPanel>
        </TabController>
      </ErrorBoundary>

      <HRSection />

      <ErrorBoundary>
        <Sections>
          <Section>
            <SectionTitle>Referral Fee</SectionTitle>
            <SectionValue>{referralFee ? referralFee : 'No referral Fee'}</SectionValue>
          </Section>
        </Sections>
        <Sections>
          <Section>
            <SectionTitle>Created on</SectionTitle>
            <SectionValue>
              {formatDate({
                date: createdAt,
                format: 'short',
              })}
            </SectionValue>
          </Section>
          {updatedAt && (
            <Section>
              <SectionTitle>Updated on</SectionTitle>
              <SectionValue>
                {formatDate({
                  date: updatedAt,
                  format: 'short',
                })}
              </SectionValue>
            </Section>
          )}
          <Section>
            {status === 'APPLIED' && (
              <>
                <SectionTitle>Updated on</SectionTitle>
                <SectionValue>
                  {formatDate({
                    date: updatedAt,
                    format: 'short',
                  })}
                </SectionValue>
              </>
            )}
          </Section>
        </Sections>
      </ErrorBoundary>
    </Container>
  );
};

const Container = styled.div``;

const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ApplicationStatus = styled.div`
  display: none;
  margin-bottom: 16px;
  border: 1px dotted ${Colors.Contrast};
  padding: 16px 0 8px 0;

  ${media.from_laptop`
    display: block;
  `}
`;

const Intro = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MainTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const PillsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 8px;

  ${media.from_laptop`
    width: fit-content;
    gap: 16px;
  `}
`;

const CompanyName = styled.div`
  display: flex;
  font-size: 2rem;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const IntroMain = styled.div`
  flex: 4;
`;

const IntroRight = styled.div`
  flex: 1;
`;

const JobTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

const Salary = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${Colors.Primary};
  margin-bottom: 8px;
`;

const Location = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.1rem;
`;

const Employment = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const EmploymentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LineWrapper = styled.div`
  border: 1px solid red;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 8px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const SectionDescription = styled.div`
  color: ${Colors.Black};
  font-size: 1rem;
  line-height: 2rem;
`;

const LocationUrl = styled(Link)`
  transform: translateY(-8px);
`;

const MapContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 400px;
  width: 100%;
`;

const MapContainerImage = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  flex: 4;
`;

const MapContainerDistance = styled.div`
  /* border: 1px solid red; */
  padding: 16px;
  flex: 1;
`;

const getActiveStep = (status: JobStatus) => {
  switch (status) {
    case JobStatus.Wishlist:
      return 1;
    case JobStatus.Applied:
      return 2;
    case JobStatus.Interview:
      return 3;
    case JobStatus.Offer:
      return 4;
    case JobStatus.Accepted:
      return 5;
    case JobStatus.Closed:
      return 5;
    default:
      return 1;
  }
};

import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobFromJobPostArgs } from '../../generated/types';

export const CREATE_JOB_FROM_JOB_POST = gql`
  mutation CreateJobFromJobPost($userUuid: String!, $boardUuid: String!, $data: JobFromJobPostInputData!) {
    createJobFromJobPost(userUuid: $userUuid, boardUuid: $boardUuid, data: $data) {
      userUuid
      boardUuid
      uuid
      isDeleted
    }
  }
`;

export const useCreateJobFromJobPost = (
  options?: MutationHookOptions<Pick<Mutation, 'createJobFromJobPost'>, MutationCreateJobFromJobPostArgs>
) =>
  useMutation<Pick<Mutation, 'createJobFromJobPost'>, MutationCreateJobFromJobPostArgs>(CREATE_JOB_FROM_JOB_POST, {
    ...options,
  });

import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveJobPostArgs } from '../../generated/types';

export const ARCHIVE_JOB_POST = gql`
  mutation ArchiveJobPost($jobUuid: String!, $isDeleted: Boolean!) {
    archiveJobPost(jobUuid: $jobUuid, isDeleted: $isDeleted) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveJobPost = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveJobPost'>, MutationArchiveJobPostArgs>
) =>
  useMutation<Pick<Mutation, 'archiveJobPost'>, MutationArchiveJobPostArgs>(ARCHIVE_JOB_POST, {
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });

import { Link } from 'react-router-dom';
import { Urls } from '../../urls';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useGetJobPost } from '../../../graph/queries/getJobPost';
import { useArchiveJobPost } from '../../../graph/mutations/archiveJobpost';
import { usePublishJobPost } from '../../../graph/mutations/publishJobpost';

import { useRouter } from '../../../hooks/useRouter';

import { ViewWrapper, JobIntroWrapper } from './View.style';
import { GenericErrorAlert, InfoAlert } from '../../../components/atoms/InfoAlert';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { PaperCollapse } from '../../../components/molecules/PaperCollapse';
import { PrimaryButton, WrapperButtons } from '../../../components/atoms/Button/Buttons';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { JobPostDescription } from '../../../components/organisms/JobPostDescription';
import { Contact, ContactsList } from '../../../components/molecules/Contact';
import { ChevronRightIcon, DeleteIcon, EditIcon, PublishIcon, UnPublishIcon } from '../../../components/atoms/Icons';
import { Colors } from '../../../styles/colors';
import { formatUrl } from '../../../utils/url';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { ConfirmDeleteModal } from '../../../components/organisms/ConfirmDeleteModal/ConfirmDeleteModal';
import { JobPostStatus } from '../../../generated/types';
import { Copy } from '../../../components/atoms/Typography';
import { MoveToBoardModal } from '../../../components/organisms/MoveToBoardModal/MoveToBoardModal';
import { useLazyGetBoards } from '../../../graph/queries/getBoards';
import { useCreateJobFromJobPost } from '../../../graph/mutations/createJobFromJobPost';

export const View = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalVisibleType, setModalVisibleType] = useState<
    '' | 'delete_jobpost' | 'publish_jobpost' | 'unpublish_jobpost' | 'move_to_board_jobpost'
  >('');
  const { navigate, query: { userUuid = '', boardUuid = '', jobUuid = '' } = {} } = useRouter();
  const { data: { jobPost } = {}, loading } = useGetJobPost({
    variables: { userUuid, jobUuid },
  });
  const [archiveJobPost] = useArchiveJobPost();
  const [publishJobPost] = usePublishJobPost();
  const [getBoards] = useLazyGetBoards();
  const [createJobFromJobPost] = useCreateJobFromJobPost();

  const canEditJobPost = jobPost?.permissions?.includes('EDIT_JOBPOST') || false;
  const canArchiveJobPost = jobPost?.permissions?.includes('ARCHIVE_JOBPOST') || false;
  const canPublishJobPost =
    (jobPost?.permissions?.includes('PUBLISH_JOBPOST') &&
      jobPost &&
      [JobPostStatus.Draft, JobPostStatus.Unpublished].includes(jobPost?.status as JobPostStatus)) ||
    false;
  const canUnPublishJobPost =
    (jobPost?.permissions?.includes('PUBLISH_JOBPOST') &&
      jobPost &&
      [JobPostStatus.Published, JobPostStatus.PendingReview].includes(jobPost?.status as JobPostStatus)) ||
    false;
  const canWhishlistJobPost = jobPost?.permissions?.includes('MOVE_TO_BOARD') || false;

  function handleEditJobClick(event: any) {
    event.preventDefault();
    navigate(
      formatUrl(`${Urls.JobOfferUpdateHome}/${Urls.JobOfferUpdateStep1}`, {
        userUuid,
        boardUuid,
        jobUuid,
      }),
      {
        state: {
          from: `/${Urls.JobPosts}/${formatUrl(Urls.JobPostView, { userUuid, jobUuid })}`,
        },
      }
    );
  }

  function handleDeleteJobClick() {
    setIsModalVisible(true);
    setModalVisibleType('delete_jobpost');
  }

  function handleMoveToBoardJobClick() {
    setIsModalVisible(true);
    setModalVisibleType('move_to_board_jobpost');
  }

  async function handleMoveToBoardJob({ boardUuid }: { boardUuid: string }) {
    try {
      toast.success(`The job post '${jobPost?.jobTitle}' was successfully moved to board.`);
      const result = await createJobFromJobPost({
        variables: {
          boardUuid,
          userUuid,
          data: {
            jobPostUuid: jobUuid,
          },
        },
      });
      if (result?.data?.createJobFromJobPost) {
        const { userUuid, boardUuid, uuid } = result.data.createJobFromJobPost;
        if (!userUuid || !boardUuid || !uuid) {
          throw new Error('Missing data from createJobFromJobPost');
        }
        setIsModalVisible(false);
        navigate(`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid: uuid })}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to move the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  function handlePublishJobClick() {
    setIsModalVisible(true);
    setModalVisibleType('publish_jobpost');
  }

  function handleUnPublishJobPost() {
    setIsModalVisible(true);
    setModalVisibleType('unpublish_jobpost');
  }

  function handleCancel(event: any) {
    event.preventDefault();
    setIsModalVisible(false);
  }

  // SUBMIT FORMS
  async function handleDeleteConfirmSubmit() {
    try {
      await archiveJobPost({
        variables: {
          jobUuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success(`The job post '${jobPost?.jobTitle}' was successfully deleted.`);
      navigate(`/${Urls.JobPosts}#your_job_posts`);
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handlePublishConfirmSubmit() {
    try {
      await publishJobPost({
        variables: {
          userUuid,
          jobUuid,
          isPublished: true,
        },
      });
      setIsModalVisible(false);
      toast.success(`The job ${jobPost?.jobTitle} was successfully published.`);
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleUnPublishConfirmSubmit() {
    try {
      await publishJobPost({
        variables: {
          userUuid,
          jobUuid,
          isPublished: false,
        },
        awaitRefetchQueries: true,
        // refetchQueries: [
        //   {
        //     query: GET_JOB_POST,
        //     variables: {
        //       userUuid,
        //       jobUuid,
        //     },
        //   },
        // ],
      });
      setIsModalVisible(false);
      toast.success(`The job ${jobPost?.jobTitle} was successfully unpublished.`);
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  if (jobPost?.isDeleted) {
    return null;
  }

  if (jobPost?.permissions?.includes('VIEW_JOBPOST') === false) {
    return <GenericErrorAlert />;
  }

  let jobComponent = null;
  if (loading) {
    jobComponent = (
      <>
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
      </>
    );
  } else if (jobPost) {
    jobComponent = (
      <>
        {jobPost.isOwner && jobPost?.status === JobPostStatus.Unpublished && (
          <InfoAlert>
            <Copy>
              Don't forget to <strong>publish</strong> your Job Post when it's ready to make it available to the
              JobBoard.io community.
            </Copy>
          </InfoAlert>
        )}
        {jobPost.isOwner && jobPost?.status === JobPostStatus.Draft && (
          <InfoAlert>
            <Copy>
              Don't forget to <strong>publish</strong> your Job Post when it's ready to make it available to the
              JobBoard.io community.
            </Copy>
          </InfoAlert>
        )}
        {jobPost.isOwner && jobPost?.status === JobPostStatus.Published && (
          <InfoAlert>
            <Copy>
              Your Job Post is <strong>published</strong> and available to the JobBoard.io community.
            </Copy>
          </InfoAlert>
        )}
        {jobPost.isOwner && jobPost?.status === JobPostStatus.PendingReview && (
          <InfoAlert>
            <Copy>
              Your Job Post is <strong>pending review</strong> by our team and will soon be available to the JobBoard.io
              community.
            </Copy>
          </InfoAlert>
        )}
        {jobPost.isOwner && jobPost?.status === JobPostStatus.Rejected && (
          <InfoAlert>
            <Copy>
              Your Job Post was <strong>rejected</strong> by our team and will not be available to the JobBoard.io
              community. Please contact us for more information.
            </Copy>
          </InfoAlert>
        )}
        <PaperCollapse
          title="Job Post Candidates"
          action={
            <WrapperButtons>
              {canWhishlistJobPost && (
                <PrimaryButton
                  iconRight={<ChevronRightIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleMoveToBoardJobClick}
                  size="small"
                >
                  Move to Board
                </PrimaryButton>
              )}

              {canArchiveJobPost && (
                <PrimaryButton
                  iconRight={<DeleteIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleDeleteJobClick}
                  size="small"
                >
                  Archive
                </PrimaryButton>
              )}

              {canEditJobPost && (
                <PrimaryButton
                  iconRight={<EditIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleEditJobClick}
                  size="small"
                >
                  Edit
                </PrimaryButton>
              )}

              {canPublishJobPost && (
                <PrimaryButton
                  iconRight={<PublishIcon color={Colors.White} />}
                  inline={true}
                  onClick={handlePublishJobClick}
                  size="small"
                >
                  Publish
                </PrimaryButton>
              )}

              {canUnPublishJobPost && (
                <PrimaryButton
                  iconRight={<UnPublishIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleUnPublishJobPost}
                  isSmall={true}
                >
                  Unpublish
                </PrimaryButton>
              )}
            </WrapperButtons>
          }
        >
          <JobIntroWrapper></JobIntroWrapper>
        </PaperCollapse>

        <JobPostDescription {...jobPost} />

        {jobPost?.contacts && jobPost?.contacts?.length > 0 && (
          <ContactsList>
            {jobPost?.contacts?.map((contact) => {
              return <Contact key={contact.uuid} {...contact} />;
            })}
          </ContactsList>
        )}
      </>
    );
  } else {
    jobComponent = <GenericErrorAlert key="error" />;
  }

  return (
    <ViewWrapper>
      <Breadcrumbs>
        <Breadcrumb>
          <Link to={`/${Urls.JobPosts}`}>Job Posts</Link>
        </Breadcrumb>
        <Breadcrumb>Job Post: {jobPost?.jobTitle}</Breadcrumb>
      </Breadcrumbs>

      {jobComponent}

      <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
        {modalVisibleType === 'move_to_board_jobpost' && (
          <MoveToBoardModal handleCancel={handleCancel} getBoards={getBoards} handleSubmit={handleMoveToBoardJob}>
            <Copy marginBottom={16}>Delete the job post</Copy>
            <Copy styleLevel={2} marginBottom={16}>
              The job post for '{jobPost?.jobTitle}' will be <b>deleted</b>.
            </Copy>
            <Copy>Please confirm.</Copy>
          </MoveToBoardModal>
        )}

        {modalVisibleType === 'delete_jobpost' && (
          <ConfirmDeleteModal
            title="Delete"
            handleCancel={handleCancel}
            handleSubmit={() => handleDeleteConfirmSubmit()}
          >
            <Copy marginBottom={16}>Delete the job post</Copy>
            <Copy styleLevel={2} marginBottom={16}>
              The job post for '{jobPost?.jobTitle}' will be <b>deleted</b>.
            </Copy>
            <Copy>Please confirm.</Copy>
          </ConfirmDeleteModal>
        )}

        {modalVisibleType === 'publish_jobpost' && (
          <ConfirmDeleteModal
            title="Publish"
            handleCancel={handleCancel}
            handleSubmit={() => handlePublishConfirmSubmit()}
          >
            <Copy marginBottom={16}>Publish the Job Post</Copy>
            <Copy styleLevel={2} marginBottom={16}>
              The job listing for '{jobPost?.jobTitle}' will be <b>published</b> and made available to the JobsBoard
              community after being reviewed by our team.
            </Copy>
            <Copy styleLevel={3} marginBottom={8}>
              Any incomplete or missing information Job Post will be flagged and not published.
            </Copy>
            <Copy styleLevel={3} marginBottom={8}>
              Any fraudulent or misleading information will be flagged and the Job Post will be removed.
            </Copy>
            <Copy styleLevel={3}>
              Please note that we reserve the right to remove any Job Post that does not comply with our{' '}
              <Link to={`/${Urls.Static}/${Urls.TermsOfUse}`}>Terms of Service</Link>.
            </Copy>
          </ConfirmDeleteModal>
        )}

        {modalVisibleType === 'unpublish_jobpost' && (
          <ConfirmDeleteModal
            title="Unpublish"
            handleCancel={handleCancel}
            handleSubmit={() => handleUnPublishConfirmSubmit()}
          >
            <Copy marginBottom={16}>Unpublish the Job Post</Copy>
            <Copy styleLevel={2}>
              The job listing for '{jobPost?.jobTitle}' will be <b>unpublished</b> and removed from the JobsBoard.io
              community.
            </Copy>
          </ConfirmDeleteModal>
        )}
      </Modal>
    </ViewWrapper>
  );
};

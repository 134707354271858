import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';

import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { ChevronRightIcon } from '../../atoms/Icons';
import { Board, SortDirection } from '../../../generated/types';
import { SelectInput, SelectItem } from '../../atoms/Form/SelectInput/SelectInput';
import { Copy } from '../../atoms/Typography';

export const MoveToBoardModal = ({ getBoards, handleCancel, handleSubmit }: ConfirmDeMoveToBoardModalProps) => {
  const [boards, setBoards] = useState<Board[]>([]);
  useEffect(() => {
    async function fetchBoard() {
      const { data: { boards } = {} } = await getBoards({
        variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
        fetchPolicy: 'cache-first', // Used for first execution
      });
      setBoards(boards);
    }

    fetchBoard();
  }, []);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data);
  };

  return (
    <>
      <H2>Move to Board</H2>
      <InfoAlert>
        <Copy>Move your Job Post to your desired board.</Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <SelectInput defaultValue={''} name="boardUuid" label="Board">
          <SelectItem value="">
            <em>None of these</em>
          </SelectItem>
          {boards.map((board) => {
            return (
              <SelectItem key={board.uuid} value={board.uuid}>
                <em>{board.title}</em>
              </SelectItem>
            );
          })}
        </SelectInput>

        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon size={1} color={Colors.White} />}>
            Confirm
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  boardUuid: Yup.string().required('Required'),
});

type FormData = Yup.InferType<typeof validationSchema>;

type ConfirmDeMoveToBoardModalProps = {
  getBoards: (a: any) => Board[];
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
};
